<template>
  <div class="article-container">
    <el-dialog
      title="访问受限"
      v-model="dialogFormVisible"
      @close="close"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <el-form ref="form" label-width="80px" @submit.prevent>
        <el-form-item label="访问密码" prop="authCode">
          <el-input v-model.trim="authCode" type="password" autocomplete="off"  @keyup.enter="initArticle"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="closeAuth">返 回</el-button>
        <el-button type="primary" @click="initArticle">校 验</el-button>
      </template>
    </el-dialog>
    <el-header>
      <div class="cover">
        <el-image fit="cover" :src="article.coverUrl">
          <template #placeholder>
            <img style="height: 100%" src="@/assets/image/loading.gif" />
          </template>
          <template #error>
            <div class="image-slot">
              <i class="fa fa-image"></i>
            </div>
          </template>
        </el-image>
      </div>
      <div class="brand visible">
        <p class="title">
          <span class="title-head animated fadeInRight">『 </span>
          <span class="title-text animated fadeInDown">{{
            article.title
          }}</span>
          <span class="title-end animated fadeInLeft"> 』</span>
        </p>
        <div class="sub-title">
          <div class="date-icon animated fadeInDown">
            <i class="iconfont icon-rili"></i>
          </div>
          <span class="animated fadeInDown"
            >{{ article.year }} 年 {{ article.month }} 月
            {{ article.day }} 日</span
          >
          <div class="date-icon meta-browse animated fadeInDown">
            <i class="iconfont icon-remen"></i>
          </div>
          <span class="animated fadeInDown">{{ article.readNum }} 浏览</span>
        </div>
      </div>
    </el-header>
    <el-main>
      <el-row justify="center" align="middle">
        <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
          <el-row justify="center">
            <el-col :xs="22" :sm="22" :md="22" :lg="14" :xl="14">
              <div class="article-content">
                <md-editor
                  v-model="article.content"
                  :previewOnly="true"
                  :theme="theme"
                  @onGetCatalog="getCataLog"
                ></md-editor>
              </div>
              <div class="article-tags">
                <a
                  href="javascript: 0"
                  v-for="(tag, index) in tags"
                  :key="index"
                  @click="this.$router.push('/tag/' + tag)"
                >
                  <i class="fa fa-tag">{{ tag }}</i>
                </a>
              </div>
              <div class="article-copyright-info">
                <p>
                  <i class="iconfont icon-GIS-TL_dayonghu"></i>本文由
                  <a href="/">{{ article.author }}</a>
                  创作，如果您觉得本文不错，请点赞
                </p>
                <p>
                  <i class="iconfont icon-fenxiang1"></i>采用
                  <a :href="licenseUrl">{{ license }}</a>
                  国际许可协议进行许可，转载前请务必署名
                </p>
                <p v-if="article.isReprint == 1">
                  <i class="iconfont icon-lianjie"></i>本文链接：<a
                    :href="article.reprintLink"
                    >{{ article.reprintLink }}</a
                  >
                </p>
                <p v-else>
                  <i class="iconfont icon-lianjie"></i>本文链接：
                  <a :href="getLocalHost()">{{ getLocalHost() }}</a>
                </p>
                <p>
                  <i class="iconfont icon-gengxin"></i>最后更新于：{{
                    article.updateTime
                  }}
                </p>
              </div>
              <div class="article-praise">
                <el-button
                  id="priseBtn"
                  @click="articlePraise"
                  :disabled="praiseDisabled"
                >
                  <i class="fa fa-thumbs-o-up"></i>
                  <span style="display: inline-block">
                    {{ article.praiseNum }}</span
                  >
                </el-button>
              </div>
              <div class="article-nav">
                <el-row v-if="Object.keys(this.navArticle).length == 1">
                  <el-col
                    :xs="24"
                    :sm="24"
                    :md="24"
                    :lg="24"
                    :xl="24"
                    v-for="(article, index) in navArticle"
                    :key="index"
                  >
                    <div
                      class="article-item"
                      v-for="(value, key) in article"
                      :key="key"
                      @click="linkToArticle(value)"
                    >
                      <el-image :src="value.coverUrl" fit="cover">
                        <template #placeholder>
                          <img
                            style="height: 100%"
                            src="@/assets/image/loading.gif"
                          />
                        </template>
                        <template #error>
                          <div class="image-slot">
                            <i class="fa fa-image"></i>
                          </div>
                        </template>
                      </el-image>
                      <a href="javascript: void(0)">
                        <span class="nav-before">{{ key }}</span>
                        <p>{{ value.title }}</p>
                        <time>{{ value.publishDate }}</time>
                      </a>
                    </div>
                  </el-col>
                </el-row>
                <el-row v-else>
                  <el-col
                    :xs="24"
                    :sm="12"
                    :md="12"
                    :lg="12"
                    :xl="12"
                    v-for="(article, index) in navArticle"
                    :key="index"
                  >
                    <div
                      class="article-item"
                      v-for="(value, key) in article"
                      :key="key"
                      @click="linkToArticle(value)"
                    >
                      <el-image :src="value.coverUrl" fit="cover">
                        <template #placeholder>
                          <img
                            style="height: 100%"
                            src="@/assets/image/loading.gif"
                          />
                        </template>
                        <template #error>
                          <div class="image-slot">
                            <i class="fa fa-image"></i>
                          </div>
                        </template>
                      </el-image>
                      <a href="javascript: void(0)">
                        <span class="nav-before">{{ key }}</span>
                        <p>{{ value.title }}</p>
                        <time>{{ value.publishDate }}</time>
                      </a>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <hr />
              <div class="article-comment">
                <h3 class="comment-title">
                  <i class="iconfont icon-pinglun1"></i> 评论
                </h3>
                <div class="comment-submit" v-show="article.isComment == 1">
                  <el-row :gutter="15">
                    <el-col
                      :xs="3"
                      :sm="2"
                      :md="2"
                      :lg="2"
                      :xl="2"
                      style="text-align: right"
                    >
                      <span
                        class="el-avatar el-avatar--circle"
                        style="height: 35px; width: 35px; line-height: 35px"
                      >
                        <img
                          v-if="commentFormState"
                          src="@/assets/image/avatar.png"
                          style="object-fit: cover"
                        />
                        <img
                          v-else
                          :src="commentForm.avatar"
                          style="object-fit: cover"
                        />
                      </span>
                    </el-col>
                    <el-col :xs="21" :sm="22" :md="22" :lg="22" :xl="22">
                      <el-form
                        ref="commentForm"
                        :model="commentForm"
                        :rules="commentRules"
                      >
                        <el-row :gutter="20">
                          <div v-if="commentFormState">
                            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                              <el-form-item prop="nickname">
                                <div class="comment-input">
                                  <el-input
                                    placeholder="必填"
                                    size="small"
                                    maxlength="15"
                                    v-model.trim="commentForm.nickname"
                                  >
                                    <template #prepend>昵称</template>
                                  </el-input>
                                </div>
                              </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                              <el-form-item prop="email">
                                <div class="comment-input">
                                  <el-input
                                    placeholder="必填"
                                    size="small"
                                    v-model.trim="commentForm.email"
                                  >
                                    <template #prepend>邮箱</template>
                                  </el-input>
                                </div>
                              </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                              <el-form-item>
                                <div class="comment-input">
                                  <el-input
                                    placeholder="选填"
                                    size="small"
                                    maxlength="30"
                                    v-model.trim="commentForm.linkUrl"
                                  >
                                    <template #prepend>网址</template>
                                  </el-input>
                                </div>
                              </el-form-item>
                            </el-col>
                          </div>
                          <el-col
                            :xs="24"
                            :sm="8"
                            :md="8"
                            :lg="8"
                            :xl="8"
                            v-else
                          >
                            <strong style="color: #ef794f">{{
                              commentForm.nickname
                            }}</strong>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <el-form-item prop="content">
                              <div class="comment-input">
                                <el-input
                                  type="textarea"
                                  :autosize="{ minRows: 5 }"
                                  v-model="commentForm.content"
                                  placeholder="请填写邮箱信息以便及时收到回复哦(。・∀・)ノ"
                                />
                              </div>
                            </el-form-item>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <div class="comment-meta">
                              <div class="emoji"></div>
                              <div class="btn">
                                <el-button
                                  type="primary"
                                  size="small"
                                  @click="commentArticle"
                                >
                                  <i class="fa fa-paper-plane"></i>
                                  发送
                                </el-button>
                              </div>
                            </div>
                          </el-col>
                        </el-row>
                      </el-form>
                    </el-col>
                  </el-row>
                </div>
                <div class="comment-content">
                  <h3 class="comment-title">{{ commentPage.total }} 条评论</h3>
                  <div
                    class="comment"
                    v-for="(comment, index) in comments"
                    :key="index"
                  >
                    <el-row :gutter="15">
                      <el-col
                        :xs="3"
                        :sm="2"
                        :md="2"
                        :lg="2"
                        :xl="2"
                        style="text-align: right"
                      >
                        <el-avatar
                          :size="35"
                          shape="square"
                          :src="comment.avatar"
                          @error="true"
                        >
                          <img src="@/assets/image/avatar.png" />
                        </el-avatar>
                      </el-col>
                      <el-col :xs="21" :sm="22" :md="22" :lg="22" :xl="22">
                        <div class="user">
                          <a href="javascript:void(0)">
                            <strong>{{ comment.nickname }}</strong>
                          </a>
                          <span class="tag-role" v-show="comment.isBlogger == 1"
                            >博主</span
                          >
                          <time>{{ comment.createTime }}</time>
                        </div>
                        <div class="meta">
                          <i
                            class="fa fa-thumbs-o-up"
                            @click="commentPraise(comment)"
                          ></i
                          >{{ comment.praiseNum }}
                          <i
                            class="fa fa-comment-o"
                            @click="replyComment(index, comment.id)"
                          ></i
                          >{{ comment.repliesNum }}
                        </div>
                        <div class="content">
                          <span>
                            <p>{{ comment.content }}</p>
                          </span>
                        </div>
                        <div class="extras">
                          <div class="extra">
                            <i
                              v-if="
                                comment.equipment &&
                                comment.equipment
                                  .toLowerCase()
                                  .indexOf('window') != -1
                              "
                              class="iconfont icon-windows"
                            ></i>
                            <i
                              v-else-if="
                                comment.equipment &&
                                comment.equipment
                                  .toLowerCase()
                                  .indexOf('mac') != -1
                              "
                              class="iconfont icon-ios"
                            ></i>
                            <i v-else class="iconfont icon-anzhuo"></i>
                            <span>{{ comment.equipment }}</span>
                          </div>
                          <div class="extra">
                            <i
                              v-if="
                                comment.browser &&
                                comment.browser
                                  .toLowerCase()
                                  .indexOf('chrome') != -1
                              "
                              class="iconfont icon-guge2"
                            ></i>
                            <i
                              v-else-if="
                                comment.browser &&
                                comment.browser
                                  .toLowerCase()
                                  .indexOf('safari') != -1
                              "
                              class="iconfont icon-Safari"
                            ></i>
                            <i
                              v-else-if="
                                comment.browser &&
                                comment.browser
                                  .toLowerCase()
                                  .indexOf('huohu') != -1
                              "
                              class="iconfont icon-huohu"
                            ></i>
                            <i v-else class="iconfont icon-Edge-01"></i>
                            <span>{{ comment.browser }}</span>
                          </div>
                        </div>
                        <div class="replies">
                          <div
                            class="comment"
                            v-for="(replies, reIndex) in comment.replies"
                            :key="reIndex"
                          >
                            <el-row :gutter="15">
                              <el-col
                                :xs="4"
                                :sm="2"
                                :md="2"
                                :lg="2"
                                :xl="2"
                                style="text-align: right"
                              >
                                <el-avatar
                                  :size="35"
                                  shape="square"
                                  :src="replies.avatar"
                                  @error="true"
                                >
                                  <img src="@/assets/image/avatar.png" />
                                </el-avatar>
                              </el-col>
                              <el-col
                                :xs="20"
                                :sm="22"
                                :md="22"
                                :lg="22"
                                :xl="22"
                              >
                                <div class="user">
                                  <a href="javascript:void(0)">
                                    <strong>{{ replies.nickname }}</strong>
                                  </a>
                                  <span
                                    class="tag-role"
                                    v-if="replies.isBlogger == 1"
                                    >博主</span
                                  >
                                  <time>{{ replies.createTime }}</time>
                                </div>
                                <div class="meta">
                                  <i
                                    class="fa fa-thumbs-o-up"
                                    @click="commentPraise(replies)"
                                  ></i
                                  >{{ replies.praiseNum }}
                                  <i
                                    class="fa fa-comment-o"
                                    @click="replyComment(index, replies.id)"
                                  ></i
                                  >{{ replies.repliesNum }}
                                </div>
                                <div class="content">
                                  <span
                                    ><p>
                                      回复<a
                                        href="javascript: void(0)"
                                        class="replies-link"
                                        >@{{ replies.sourceNickname }}</a
                                      >
                                    </p></span
                                  >
                                  <span
                                    ><p>{{ replies.content }}</p></span
                                  >
                                </div>
                                <div class="extras">
                                  <div class="extra">
                                    <i
                                      v-if="
                                        replies.equipment &&
                                        replies.equipment
                                          .toLowerCase()
                                          .indexOf('window') != -1
                                      "
                                      class="iconfont icon-windows"
                                    ></i>
                                    <i
                                      v-else-if="
                                        replies.equipment &&
                                        replies.equipment
                                          .toLowerCase()
                                          .indexOf('mac') != -1
                                      "
                                      class="iconfont icon-ios"
                                    ></i>
                                    <i v-else class="iconfont icon-anzhuo"></i>
                                    <span>{{ replies.equipment }}</span>
                                  </div>
                                  <div class="extra">
                                    <i
                                      v-if="
                                        replies.browser &&
                                        replies.browser
                                          .toLowerCase()
                                          .indexOf('chrome') != -1
                                      "
                                      class="iconfont icon-guge2"
                                    ></i>
                                    <i
                                      v-else-if="
                                        replies.browser &&
                                        replies.browser
                                          .toLowerCase()
                                          .indexOf('safari') != -1
                                      "
                                      class="iconfont icon-Safari"
                                    ></i>
                                    <i
                                      v-else-if="
                                        replies.browser &&
                                        replies.browser
                                          .toLowerCase()
                                          .indexOf('huohu') != -1
                                      "
                                      class="iconfont icon-huohu"
                                    ></i>
                                    <i v-else class="iconfont icon-Edge-01"></i>
                                    <span>{{ replies.browser }}</span>
                                  </div>
                                </div>
                              </el-col>
                            </el-row>
                          </div>
                        </div>
                        <div
                          class="comment-submit"
                          v-if="commentSubmit == index"
                          v-show="article.isComment == 1"
                        >
                          <el-row :gutter="15">
                            <el-col
                              :xs="3"
                              :sm="2"
                              :md="2"
                              :lg="2"
                              :xl="2"
                              style="text-align: right"
                            >
                              <span
                                class="el-avatar el-avatar--circle"
                                style="
                                  height: 35px;
                                  width: 35px;
                                  line-height: 35px;
                                "
                              >
                                <img
                                  v-if="commentFormState"
                                  src="@/assets/image/avatar.png"
                                  style="object-fit: cover"
                                />
                                <img
                                  v-else
                                  :src="commentForm.avatar"
                                  style="object-fit: cover"
                                />
                              </span>
                            </el-col>
                            <el-col
                              :xs="21"
                              :sm="22"
                              :md="22"
                              :lg="22"
                              :xl="22"
                            >
                              <el-form
                                ref="replyCommentForm"
                                :model="commentForm"
                                :rules="commentRules"
                              >
                                <el-row :gutter="20">
                                  <div v-show="commentFormState">
                                    <el-col
                                      :xs="24"
                                      :sm="8"
                                      :md="8"
                                      :lg="8"
                                      :xl="8"
                                    >
                                      <el-form-item prop="nickname">
                                        <div class="comment-input">
                                          <el-input
                                            placeholder="必填"
                                            size="small"
                                            maxlength="15"
                                            v-model.trim="commentForm.nickname"
                                          >
                                            <template #prepend>昵称</template>
                                          </el-input>
                                        </div>
                                      </el-form-item>
                                    </el-col>
                                    <el-col
                                      :xs="24"
                                      :sm="8"
                                      :md="8"
                                      :lg="8"
                                      :xl="8"
                                    >
                                      <el-form-item prop="email">
                                        <div class="comment-input">
                                          <el-input
                                            placeholder="必填"
                                            size="small"
                                            v-model.trim="commentForm.email"
                                          >
                                            <template #prepend>邮箱</template>
                                          </el-input>
                                        </div>
                                      </el-form-item>
                                    </el-col>
                                    <el-col
                                      :xs="24"
                                      :sm="8"
                                      :md="8"
                                      :lg="8"
                                      :xl="8"
                                    >
                                      <el-form-item>
                                        <div class="comment-input">
                                          <el-input
                                            placeholder="选填"
                                            size="small"
                                            maxlength="30"
                                            v-model.trim="commentForm.linkUrl"
                                          >
                                            <template #prepend>网址</template>
                                          </el-input>
                                        </div>
                                      </el-form-item>
                                    </el-col>
                                  </div>
                                  <el-col
                                    :xs="24"
                                    :sm="8"
                                    :md="8"
                                    :lg="8"
                                    :xl="8"
                                    v-show="!commentFormState"
                                  >
                                    <strong style="color: #ef794f">{{
                                      commentForm.nickname
                                    }}</strong>
                                  </el-col>
                                  <el-col
                                    :xs="24"
                                    :sm="24"
                                    :md="24"
                                    :lg="24"
                                    :xl="24"
                                  >
                                    <el-form-item prop="content">
                                      <div class="comment-input">
                                        <el-input
                                          type="textarea"
                                          :autosize="{ minRows: 5 }"
                                          v-model="commentForm.content"
                                          placeholder="请填写邮箱信息以便及时收到回复哦(。・∀・)ノ"
                                        />
                                      </div>
                                    </el-form-item>
                                  </el-col>
                                  <el-col
                                    :xs="24"
                                    :sm="24"
                                    :md="24"
                                    :lg="24"
                                    :xl="24"
                                  >
                                    <div class="comment-meta">
                                      <div class="emoji"></div>
                                      <div class="btn">
                                        <el-button
                                          type="primary"
                                          size="small"
                                          @click="commentArticle(1)"
                                        >
                                          <i class="fa fa-paper-plane"></i>
                                          发送
                                        </el-button>
                                      </div>
                                    </div>
                                  </el-col>
                                </el-row>
                              </el-form>
                            </el-col>
                          </el-row>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <div
                    class="comment-more"
                    @click="getMoreComment"
                    v-show="
                      commentPage.curPage * commentPage.pageSize <
                      commentPage.total
                    "
                  >
                    查看更多
                  </div>
                  <div class="comment-loading" v-loading="commentLoading"></div>
                  <el-empty
                    v-show="comments.length == 0"
                    description="文章暂无评论哦~"
                  />
                </div>
              </div>
            </el-col>
            <el-col :xs="0" :sm="0" :md="0" :lg="5" :xl="5" :offset="1">
              <div :class="sidebarDiv">
                <el-tabs type="card">
                  <el-tab-pane>
                    <template #label>
                      <i class="fa fa-list-ol"></i>
                      <div class="tabName">目录</div>
                    </template>
                    <el-tree
                      :data="titles"
                      :props="defaultProps"
                      node-key="id"
                      empty-text=""
                      highlight-current
                      ref="treeTitles"
                      @node-click="handlerScroll"
                      class="tree-node"
                      accordion
                    />
                  </el-tab-pane>
                </el-tabs>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
import {
  getArticleLink,
  getArticleCustomLink,
  praiseArticle,
  commentArticle,
  getCommentByArticleId,
  praiseComment,
} from "@/api/article";
import {
  setArticlePraise,
  getArticlePraise,
  setCommentPraise,
  getCommentPraise,
} from "@/utils/accessStorage";
import MdEditor from "md-editor-v3";
import "md-editor-v3/lib/style.css";
import { ElMessage } from "element-plus";
import { mapState } from "vuex";
export default {
  name: "index",
  components: {
    MdEditor,
  },
  data() {
    const validateemail = (rule, value, callback) => {
      const regEmail =
        /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (
        this.commentForm.email != "" &&
        !regEmail.test(this.commentForm.email)
      ) {
        callback(new Error("请输入有效的邮箱"));
      } else {
        callback();
      }
    };
    return {
      titles: [],
      defaultProps: {
        children: "children",
        label: "text",
      },
      article: {},
      navArticle: [],
      tags: [],
      theme: this.$store.state.theme,
      activeType: "",
      sidebarDiv: "sidebar",
      isScroll: true,
      commentForm: {
        nickname: "",
        email: "",
        linkUrl: "",
        content: "",
        avatar: "",
      },
      commentFormState: true,
      commentRules: {
        nickname: [{ required: true, trigger: "blur", message: "请输入昵称" }],
        email: [
          { required: true, trigger: "blur", message: "请输入邮箱" },
          { validator: validateemail, trigger: "blur" },
        ],
        content: [{ required: true, trigger: "blur", message: "请输入内容" }],
      },
      commentPage: {
        curPage: 1,
        pageSize: 10,
        total: 0,
      },
      comments: [],
      commentLoading: true,
      commentSubmit: -1,
      praiseDisabled: true,
      dialogFormVisible: false,
      authCode: "",
    };
  },
  created() {
    this.initArticle();
    const user = JSON.parse(window.localStorage.getItem("user"));
    if (user != null) {
      this.commentForm.nickname = user.nickname;
      this.commentForm.email = user.email;
      this.commentForm.avatar = user.avatar;
      this.commentFormState = false;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    async initArticle() {
      let linkType = this.$route.meta.linkType;
      if (linkType == "link") {
        const year = this.$route.params.year;
        const month = this.$route.params.month;
        const day = this.$route.params.day;
        const title = this.$route.params.title;
        await getArticleLink(year, month, day, title, this.authCode).then(
          (res) => {
            if (res.code == 200) {
              this.article = res.data.article;
              this.navArticle = res.data.navArticle;
              this.dialogFormVisible = false;
            } else if (res.code == 401) {
              this.authCode=""
              this.dialogFormVisible = true;
              this.$message({ type: "error", message: res.message });
            } else {
              this.$router.push("/404");
            }
          }
        );
      } else {
        const link = this.$route.params.link;
        await getArticleCustomLink(link, this.authCode).then((res) => {
          if (res.code == 200) {
            this.article = res.data.article;
            this.navArticle = res.data.navArticle;
            this.dialogFormVisible = false;
          } else if (res.code == 401) {
            this.authCode=""
            this.dialogFormVisible = true;
            this.$message({ type: "error", message: res.message });
          } else {
            this.$router.push("/404");
          }
        });
      }
      if (this.dialogFormVisible) {
        document.title =
          "访问受限 - " + this.$store.getters.configs["blogName"];
        return;
      }
      if (this.article.tags != null && this.article.tags != "") {
        this.tags = this.article.tags.split(",");
      }
      this.initComment();
      this.commentForm.articleId = this.article.id;
      this.praiseDisabled = false;
      document.title =
        this.article.title + " - " + this.$store.getters.configs["blogName"];
    },
    initComment() {
      this.commentLoading = true;
      this.commentPage.id = this.article.id;
      getCommentByArticleId(this.commentPage).then((res) => {
        if (res.code == 200) {
          this.comments = res.data.comments;
          this.commentPage.total = res.data.count;
        }
        this.commentLoading = false;
      });
    },
    getMoreComment() {
      this.commentPage.curPage = this.commentPage.curPage + 1;
      this.commentLoading = true;
      getCommentByArticleId(this.commentPage).then((res) => {
        if (res.code == 200) {
          this.comments = this.comments.concat(res.data.comments);
        }
        this.commentLoading = false;
      });
    },
    getCataLog(list) {
      //创建一个数组
      let titleList = [];
      //遍历标题列表
      list.forEach((item, index) => {
        item.id = index;
        //判断数组长度是否为0
        if (titleList.length == 0) {
          //没数据直接添加
          titleList.push(item);
        } else {
          //获取标题数组最后一个
          let lastItem = titleList[titleList.length - 1];
          //判断当前层级是否大于最后一个数组
          if (item.level > lastItem.level) {
            //遍历最后数据的层级 + 1
            for (let i = lastItem.level + 1; i <= 6; i++) {
              //获取子节点
              const { children } = lastItem;
              //没有子节点直接添加
              if (!children) {
                lastItem.children = [item];
                break;
              }

              lastItem = children[children.length - 1];

              if (item.level <= lastItem.level) {
                children.push(item);
                break;
              }
            }
          } else {
            //如果不大于直接添加
            titleList.push(item);
          }
        }
      });
      this.titles = titleList;
    },
    handlerScroll(node) {
      document.getElementById(node.text).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop < 400) {
        this.sidebarDiv = "sidebar";
      } else {
        this.sidebarDiv = "sidebar sidebar-affix";
      }
      let titles = document
        .getElementsByClassName("article-content")[0]
        .querySelectorAll("h1,h2,h3,h4,h5,h6");
      let nodesMap = this.$refs.treeTitles.store.nodesMap;
      let index = -1;
      for (let i = 0; i < titles.length; i++) {
        nodesMap[i].expanded = false;
        if (titles[i].offsetTop <= scrollTop) {
          index = i;
        }
      }
      this.$refs.treeTitles.setCurrentKey(index);
    },
    articlePraise() {
      if (this.praiseDisabled) {
        return;
      }
      if (getArticlePraise(this.article.id)) {
        setArticlePraise(this.article.id);
        praiseArticle(this.article.id).then((res) => {
          if (res.code == 200) {
            this.article.praiseNum = this.article.praiseNum + 1;
            ElMessage({
              type: "success",
              message: res.message,
            });
          } else {
            ElMessage.error(res.message);
          }
        });
      } else {
        ElMessage.error("已点赞，请勿重复操作");
      }
    },
    getLocalHost() {
      return decodeURI(window.location.href);
    },
    linkToArticle(item) {
      if (item.customLink) {
        window.location.href = "/article/" + item.customLink + ".html";
      } else {
        window.location.href = "/article/" + item.link;
      }
    },
    commentArticle(type) {
      if (this.article.isComment == 1) {
        const submitComment = () => {
          commentArticle(this.commentForm).then((res) => {
            if (res.code == 200) {
              ElMessage({
                type: "success",
                message: res.message,
              });
              this.initComment();
              this.commentFormState = false;
              this.commentForm.avatar = res.data;
              this.commentForm.content = "";
              // 存储用户的评论信息
              let user = {};
              user.nickname = this.commentForm.nickname;
              user.email = this.commentForm.email;
              user.avatar = res.data;
              window.localStorage.setItem("user", JSON.stringify(user));
              // 移除回复表单
              this.commentSubmit = -1;
              delete this.commentForm.commentId;
            } else {
              ElMessage.error(res.message);
            }
          });
        };
        if (type == 1) {
          this.$refs.replyCommentForm.validate((valid) => {
            if (valid) {
              submitComment();
            }
          });
        } else {
          this.$refs.commentForm.validate((valid) => {
            if (valid) {
              submitComment();
            }
          });
        }
      }
    },
    replyComment(index, id) {
      this.commentSubmit = index;
      this.commentForm.commentId = id;
    },
    commentPraise(comment) {
      if (getCommentPraise(this.article.id, comment.id)) {
        setCommentPraise(this.article.id, comment.id);
        praiseComment(comment.id).then((res) => {
          if (res.code == 200) {
            comment.praiseNum = comment.praiseNum + 1;
            ElMessage({
              type: "success",
              message: res.message,
            });
          } else {
            ElMessage.error(res.message);
          }
        });
      } else {
        ElMessage.error("已点赞，请勿重复操作");
      }
    },
    closeAuth() {
      this.$router.back();
    },
  },
  computed: {
    getTheme() {
      return this.$store.state.theme;
    },
    ...mapState({
      license: (state) => state.settings.configs.license,
      licenseUrl: (state) => state.settings.configs.licenseUrl,
    }),
  },
  watch: {
    getTheme() {
      this.theme = this.$store.state.theme;
    },
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.article-container {
  height: 100%;
  width: 100%;
}
.el-header {
  position: relative;
  margin-bottom: 1rem;
  object-fit: cover;
  border: none;
  filter: brightness(0.9);
  height: 100%;
}
.article-container .cover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  border: none;
  text-align: center;
}
.article-container .cover .el-image {
  width: 100%;
  height: 30rem;
  object-fit: cover;
  border: none;
}
.article-container .brand {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #fff;
  height: 30rem;
  text-shadow: 0 0.1875rem 0.3125rem #1c1f21;
  opacity: 0;
  border: none;
}

.article-container .brand p.title {
  position: relative;
  font-family: "BiaoSong", sans-serif;
  font-size: 3.2em;
  line-height: 1.2;
  z-index: 100;
}

.article-container .brand p.title span {
  display: inline-block;
  animate-duration: 2s;
}

.article-container .brand .sub-title {
  position: relative;
  margin: 1rem 0 0 0;
  font-family: sans-serif;
  font-size: 18px;
  animation: slowUp 1s;
  transition: opacity 0.2s, slowUp 0.4s;
  z-index: 99;
}

.article-container .brand .date-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  background: #3a8ee5;
  color: #000;
  margin: 0 10px;
  line-height: 20px;
  font-size: 14px;
  vertical-align: text-top;
}

.article-container .brand .date-icon i {
  padding: 0;
}

.article-container .brand .meta-browse {
  margin-left: 20px;
  background: #e9495b;
}
.md-dark {
  background-color: rgba(0, 0, 0, 0);
  border-color: var(--border-color);
  z-index: 99;
}
.tree-node {
  color: var(--text-color);
  background-color: rgba(0, 0, 0, 0);
  cursor: url("../../assets/mouse/link.png"), pointer !important;
  font-size: 16px !important;
}
.tree-node:hover {
  background: none;
}
.tabName {
  display: inline-block;
}
.sidebar-affix {
  position: fixed;
  top: 100px;
}
.image-slot {
  text-align: center;
  font-size: 30px;
  line-height: 480px;
}
.article-tags {
  margin: 1rem 0;
}
.article-tags a {
  display: inline-block;
  padding: 0 0.75rem;
  border-radius: 0.3125rem;
  background: var(--panel-color);
  color: var(--primary-color);
  margin-right: 6px;
}
.article-tags a:hover {
  background: var(--primary-color);
  color: var(--background-color);
}
.article-praise {
  margin: 1rem auto;
  text-align: center;
}
.article-praise #priseBtn {
  width: 70px;
  height: 70px;
  border: 0;
  background: var(--red-color);
  color: #fff;
  padding: 1rem;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 1px 30px -10px var(--shadow-color);
  transition: all 0.3s;
  font-size: 16px;
}
.article-praise #priseBtn:hover {
  opacity: 0.8;
  box-shadow: 0 1px 20px -4px var(--red-color);
}

.article-copyright-info {
  background-color: rgba(189, 189, 189, 0.2);
  border-left: 2px solid #354b58;
  border-radius: 5px;
  letter-spacing: 0;
  font-size: 1.2rem;
  line-height: 1.5em;
  padding: 10px;
  margin-top: 50px;
  color: var(--text-color);
}

.article-copyright-info p i {
  padding: 0 5px;
}

.article-copyright-info p a {
  text-decoration: none;
  color: var(--primary-color);
  word-break: break-all;
}
.article-nav {
  margin-top: 20px;
}
.article-nav .article-item {
  position: relative;
  height: 200px;
  transition: all 0.3s;
  padding: 2rem;
}
.article-nav .article-item .el-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  object-fit: cover;
  z-index: -1;
  filter: brightness(0.8);
  transition: all 0.3s;
  text-align: center;
}
.article-nav .article-item:hover .el-image {
  filter: brightness(0.5);
}
.article-nav .article-item a {
  display: block;
  color: #e1e1e1;
  margin: 10px 0;
  font-size: 18px;
  text-shadow: none;
  text-decoration: none;
}
.article-nav .article-item a p:hover {
  color: var(--primary-color);
}
.article-nav .article-item a span {
  display: block;
  color: var(--primary-color);
}
.article-nav .article-item a .nav-before::after {
  content: "";
  position: absolute;
  top: 75px;
  left: 30px;
  height: 1px;
  width: 80px;
  background-color: var(--primary-color);
}
.article-nav .el-col:nth-of-type(even) .article-item a,
.article-nav .el-col:nth-of-type(even) .article-item p {
  text-align: right;
}
.article-nav .el-col:nth-of-type(even) .article-item a .nav-before::after {
  left: unset;
  right: 30px;
}

@media screen and (max-width: 768px) {
  .article-container .brand p.title {
    font-size: 2.2rem;
  }
}

/********** 评论样式 **********/

.el-form-item {
  margin-bottom: 5px;
}
.el-form >>> .el-form-item__error {
  padding-top: 0;
  top: 10px;
  left: unset;
  right: 0;
}

.el-form >>> .el-form-item.is-error .el-input__inner,
.el-form >>> .el-form-item.is-error .el-textarea__inner {
  border-color: var(--el-color-danger) !important;
}

.comment-title {
  color: var(--text-color);
  font-weight: 400;
  margin: 1rem 0;
}

.comment-input >>> .el-input .el-input__inner,
.comment-input >>> .el-textarea .el-textarea__inner {
  color: var(--text-color);
  background-color: transparent;
  border-color: #90939950;
}

.comment-input >>> .el-textarea .el-textarea__inner {
  background-image: url("../../assets/image/google.gif");
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.comment-input >>> .el-textarea .el-textarea__inner:focus {
  background-position-y: 150px;
  transition: all 0.2s ease-in-out 0s;
}

.comment-input >>> .el-input .el-input-group__prepend {
  background-clip: padding-box;
  background-color: #90939920;
  border-color: #90939950;
  color: var(--text-color);
}

.comment-input >>> .el-input .el-input__inner:hover,
.comment-input >>> .el-textarea .el-textarea__inner:hover {
  border-color: #90939980;
}

.comment-input >>> .el-input .el-input__inner:focus,
.comment-input >>> .el-textarea .el-textarea__inner:focus {
  border-color: var(--primary-color);
}

.comment-meta .btn {
  text-align: right;
}
.comment-meta .btn .el-button {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.comment-meta .btn .el-button:hover {
  box-shadow: 0 1px 12px -4px var(--primary-color);
}
.comment-submit,
.comment {
  padding: 20px;
  position: relative;
  z-index: 99;
}
.comment .user,
.comment .meta {
  display: inline-block;
}
.comment .user a {
  text-decoration: none;
  color: #ef794f;
}
.comment .user .tag-role {
  background-color: #67c23a20;
  border: 1px solid var(--primary-color);
  border-radius: 2px;
  color: var(--primary-color);
  display: inline-block;
  padding: 0 0.5em;
  font-size: 0.75em;
  margin-left: 10px;
  font-family: "Merriweather Sans", Helvetica, Tahoma, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei", "WenQuanYi Micro Hei", sans-serif;
}
.comment .user time {
  color: var(--time-color);
  margin-left: 10px;
  font-weight: inherit;
  font-size: 12px;
}
.comment .meta {
  float: right;
  color: #409eff;
}
.comment .meta i {
  margin-left: 0.7rem;
  margin-right: 0.25rem;
  cursor: url("../../assets/mouse/link.png"), pointer;
}
.comment .meta .fa-thumbs-o-up:hover:before {
  content: "\f164";
}
.comment .meta .fa-comment-o:hover:before {
  content: "\f075";
}
.comment .content {
  margin-top: 0.5rem;
  overflow: auto;
  max-height: 500px;
  background: var(--panel-color);
  color: var(--text-color);
  padding: 0 16px;
  border-radius: 12px;
}

.comment .content span p {
  font-size: 15px;
}

.comment .extras .extra {
  font-size: 12px;
  display: inline-block;
  color: var(--time-color);
  padding-right: 10px;
}

.comment .extras .extra i {
  font-size: 10px;
  padding: 0 5px;
}

.comment-more {
  width: 100%;
  cursor: pointer;
  padding: 0.75em;
  text-align: center;
  transition: all 0.5s;
  color: var(--text-color);
}
.comment-more:hover {
  background-color: #00000020;
}

.comment-loading {
  width: 100%;
  cursor: pointer;
  padding: 0.75em;
  text-align: center;
  transition: all 0.5s;
  background-color: rgba(0, 0, 0, 0);
}
.comment-loading i {
  animation: rotate 1s linear infinite;
}

.replies-link {
  text-decoration: none;
  color: #3f8cd9;
}

.article-container >>> .el-dialog {
  width: 400px !important;
}

@media screen and (max-width: 768px) {
  .comment .meta {
    float: none;
  }
  .article-container >>> .el-dialog {
    width: 100% !important;
  }
}
</style>
